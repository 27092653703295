@media screen and (max-width: 425px) {

  .circle-green {
    position: absolute;
    top: 0;
    left: 0;
  }
  .circle-rose {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
  .points {
    position: absolute;
    bottom: 0;
    left: 0;
    max-height: 71px;
    z-index: 0;
  }


  .main-container {
    padding: 40px;
    padding-top: 30px;
    margin-top: -60px;
  

    .main-container__left {
      width: 272px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      h3 {
        font-size: 40px;
      }

      .header {
        font-size: 39px;
      }

      .description {
        max-width: 253px;
        font-size: 0.875rem;
      }

      .links {
        justify-content: space-between;
        a {
          margin-left: 20px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }

    .main-container__right {
      display: none;
    }
  }

  a {
    font-size: 0.875rem;
  }

}
